<template>
  <div class="container-header">
    <Splash v-if="showSplash" @start-exploring="startExploring" />
      <div class="menu-constraint">
      <div class="header-links">
          <router-link to="/">Map</router-link>
          <router-link to="/about">About the Data</router-link>
          <!-- <router-link to="/data">Querity</router-link> -->
      </div>
      <div class="header-title">
        <h2>RTI Rarity™ Dashboard</h2>
      </div>
      <div class="header-logo-trademark">
        <a href="https://www.rti.org" class="RTILogo"
          ><img src="../assets/images/RTI-logo-white.svg"
        /></a>
      </div>
    </div>
  </div>
</template>

<script>
import Splash from "./Splash.vue";
export default {
  components: {
    Splash,
  },
  data() {
    return {
      showSplash: true,
    };
  },
  methods: {
    startExploring() {
      // Hide the splash screen when the user clicks "Begin Exploring"
      this.showSplash = false;
    },
  },
};
</script>

<style scoped>

.header-links{
 font-family: Open Sans, Helvetica, Arial sans-serif;
 letter-spacing: 0.5px;
 padding-top: 8px;
 color: #04254d;

}

.container-header {

  display: flex;
  justify-content: space-between;
  background-color: rgb(5, 46, 95);
  padding: 1rem 1rem;
  font-weight: 300;
}

.header-title {
  color: white;
  font-family: Open Sans, Helvetica, Arial sans-serif;
  letter-spacing: 0.25px;
}

.header-title h2 {
  margin: 0;
  margin-left: 2rem;

}

a {
  font-size: 1.1rem;

  color: white;
  padding-left: 25px;
  padding-right: 10px;
}
.header-logo-trademark {
  display: flex;
  white-space: nowrap;

}
.RTILogo {
  height: 8px;
  width: 150px;
  padding-right: 1rem;
  padding-left: 1rem;
  float: right;
}
.menu-constraint{
  display: flex;
  justify-content: space-between;
  font-weight: 300;
  width: 97%;
}
</style>
