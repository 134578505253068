<template>
  <div v-if="loading" class="text-center">
    <b-spinner variant="primary" label="Loading..."></b-spinner>
  </div>
  <div class="container-map" v-else>
    <l-map :zoom="zoom" :center="center" :options="mapOptions" @update:bounds="boundsUpdated" @update:zoom="zoomUpdated"
      @click="handleClick" ref="HRSAmap" style="height: 100%; width: 100%">
      <l-control-zoom position="bottomright"></l-control-zoom>
      <v-geosearch :options="geosearchOptions"></v-geosearch>
      <l-control-layers position="topright" :autoZIndex="false"></l-control-layers>
      <l-control position="topleft" style="height: 100%">
        <button class="show-layer-btn on-hover-style" @click="showLayers = !showLayers"
          style="showLayers === true ? 'display: none;' : 'display: contents;' ">
          <i class="fa fa-solid fa-bars fa-4x"
            :style="showLayers === true ? 'display: none;' : 'display: contents;' "></i>
        </button>
        <div ref="sidePanel" class="layer-content" v-show="showLayers" style="background: white; padding: 0.5rem;"
          :style="{ 'height': parentHeight + 'px'  }">
          <div class="top-layer-panel">
            <div class="close-sidebar">
              <button class="show-layer-btn on-hover-style" @click="showLayers = !showLayers"
                style="background: white; border:none; float: right;">
                <i class="fa fa-chevron-left fa-2x"></i><i class="fa fa-chevron-left fa-2x"></i>
              </button>
            </div>
            <div class="bubble-container">
              <div class="layer-bubbles" v-for="(layer, i) in visibleLayers" :key="i" position="topright">
                <b-button v-if="layer.label !== 'National Counties'" class="layer-pill" @click="removeLayer(layer)">
                  {{ layer.label }} <i class="fa fa-close"></i>
                </b-button>
              </div>
            </div>
          </div>
          <div class="container-dropdown">
            <!-- <RecursiveParent @on-select="onSelect" :layers="Scenarios" :depth="0" /> -->
            <RecursiveParent @on-select="onSelect" :layers="RecursiveLayers" :depth="0" />
          </div>
        </div>
      </l-control>
      <l-control v-for="(layer, i) in visibleLayers" :key="i" position="topright">
        <div class="legend-toolbar">
          <button v-if="layer.label !== 'National Counties'" class="legend-btn" @click="hideLegend(layer.layers)"
            :id="`${layer.layers}-expand`">
            <i class="fa fa-angle-double-down"></i>
          </button>
          <b-tooltip v-if="layer.label !== 'National Counties'" :target="`${layer.layers}-expand`" triggers="hover"
            placement="left">
            {{ layer.label }}
          </b-tooltip>
          <button v-if="layer.label !== 'National Counties'" class="legend-btn" @click="removeLayer(layer)">
            <b><i class="fa fa-solid fa-remove"></i></b>
          </button>
        </div>
        <div v-if="layer.label !== 'National Counties'" class="legend-content"
          style="background: white; padding: 0.5rem" :id="layer.layers">
          <div>
            <p>{{ Object.values(layer.display)[0] }}:</p>
            <img :src="`${baseUrl}?service=WMS&request=GetLegendGraphic&format=image%2Fpng&scale=100000&transparent=true&layer=${
              layer.layers.split('CWBNational:')[1]
            }`" />
          </div>
        </div>
      </l-control>
      <l-control-scale :position="dynamicPostion" :imperial="true" :metric="false"></l-control-scale>
      <l-control :position="dynamicPostion">
        <img src="../assets/images/north-compass.svg" alt="north-compass" height="30" width="30" />
      </l-control>
      <l-layer-group ref="popupLayer" v-show="markerLatLng != null">
        <l-popup v-show="popupContent != ''" :lat-lng="markerLatLng" :content="popupContent" class="popup-info">
        </l-popup>
      </l-layer-group>
      <l-tile-layer v-for="tileProvider in tileProviders" :key="tileProvider.name" :name="tileProvider.name"
        :visible="tileProvider.visible" :url="tileProvider.url" :attribution="
          `${tileProvider.attribution} | <a href='/about'>Data sources</a>`
        " layer-type="base"></l-tile-layer>
      <l-layer-group name="wms-layers">
        <l-wms-tile-layer v-for="layer in wmsLayers" :v-if="layer.format === 'image/png'" :key="layer.layers"
          :base-url="baseUrl" :layers="layer.layers" :visible="layer.visible" :format="layer.format"
          :transparent="layer.transparent" layer-type="overlay" :name="layer.label" :zIndex="layer.zIndex" />
      </l-layer-group>
      <l-geo-json v-if="zoom >= 9" :geojson="National_counties" :options-style="hide_county_styles"></l-geo-json>
      <l-geo-json v-if="zoom < 9" :geojson="National_counties" :options-style="counties_styles"></l-geo-json>
      <l-geo-json :geojson="National_states" :options-style="states_styles"></l-geo-json>
      <!-- <l-geo-json v-if="zoom < 9" :geojson="National_states" :options-style="states_styles"></l-geo-json>
      <l-geo-json v-if="zoom >= 9" :geojson="National_states" :options-style="hide_states_styles"></l-geo-json> -->
    </l-map>
  </div>
</template>

<script>
import {
  LMap,
  LTileLayer,
  LWMSTileLayer,
  LLayerGroup,
  LPopup,
  LControl,
  LControlZoom,
  LControlLayers,
  LControlScale,
  LGeoJson,
} from "vue2-leaflet";
// import { latLngBounds } from "leaflet";
import L from "leaflet";
import Dropdown from "./Dropdown.vue";
import RecursiveParent from "./RecursiveParent.vue";
import { OpenStreetMapProvider } from "leaflet-geosearch";
import layerData from "../assets/data/national_layers.json";
// import LProtobuf from 'vue2-leaflet-vectorgrid';
import { BIcon } from 'bootstrap-vue'
// import $ from "jquery";

export default {
  name: "Dashboard",
  components: {
    LMap,
    LTileLayer,
    LLayerGroup,
    "l-wms-tile-layer": LWMSTileLayer,
    LControl,
    LControlZoom,
    LControlLayers,
    LControlScale,
    Dropdown,
    RecursiveParent,
    LPopup,
    LGeoJson,
    BIcon
    // LProtobuf
  },
  data() {
    return {
      loading: true,
      dataZoomLevel: "state",
      window: window,
      zoom: 5,
      center: [39.8, -98.6],
      markerLatLng: null,
      bounds: null,
      // maxBounds: latLngBounds([
      //   [37, -70],
      //   [43, -79],
      // ]),
      showLegend: true,
      showLayers: false,
      popupContent: "",
      zoomToSynthPop: false,
      clicks: 0,
      timer: null,
      baseUrl: "",
      wmsLayers: layerData.wmsLayers,
      counties_styles: {
        weight: 1,
        color: "#c4c4c4",
        opacity: 1,
        fillColor: "#FFFFFF",
        fillOpacity: 0,
      },
      hide_county_styles: {
        weight: 1.4,
        color: "#707070",
        opacity: 0,
        fillColor: "#FFFFFF",
        fillOpacity: 0,
        dashArray: "1 2",
      },
      states_styles: {
        weight: 2.4,
        color: "#4a4949",
        opacity: 1,
        fillColor: "#FFFFFF",
        fillOpacity: 0,
      },
      hide_states_styles: {
        weight: 1.4,
        color: "#707070",
        opacity: 0,
        fillColor: "#FFFFFF",
        fillOpacity: 0,
        dashArray: "1 2",
      },
      geosearchOptions: {
        provider: new OpenStreetMapProvider({
          params: {
            countrycodes: "us"
          }
        }),
        showMarker: true,
        position: "topright",
      },
      tileProviders: [
        {
          name: "Low-detail Grayscale",
          url:
            "https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}",
          attribution: "Tiles &copy; Esri &mdash; Esri, DeLorme, NAVTEQ",
          visible: false,
        },
        {
          name: "High-detail Terrain",
          url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
          visible: true,
          attribution:
            '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
        },
        // {
        //   name: "Low-detail Terrain",
        //   url: "https://stamen-tiles.a.ssl.fastly.net/terrain/{z}/{x}/{y}.png",
        //   attribution:
        //     'Map tiles by <a href="http://stamen.com">Stamen Design</a>, <a href="http://creativecommons.org/licenses/by/3.0">CC BY 3.0</a> &mdash; Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
        //   visible: false,
        // }
      ],
      mapOptions: { zoomControl: false, scrollWheelZoom: false },
      // parentHeight: 0
    };
  },
  async created() {
    this.loading = true;
    this.baseUrl = process.env.NODE_ENV === "development" ? "https://rtirarity.io/geoserver/CWBNational/wms" : `${new URL("/geoserver/CWBNational/wms", location.href).href}`;
    console.log(this.baseUrl)
    this.loading = false;
  },
  computed: {
    parentHeight() {
      // this.parentHeight = window.innerHeight;
      return window.innerHeight - 70.4;
    },
    dynamicPostion() {
      return this.showLayers === true ? 'bottomright' : 'bottomleft'
    },
    National_counties() {
      return window.National_counties;
    },
    National_states() {
      return window.National_states;
    },
    baseLayers() {
      return [
        {
          label: "National Counties",
          visible: true,
          format: "image/png",
          layers: "CWBNational:counties",
          transparent: true,
          type: "Polygon",
          zIndex: 20,
          display: { county_boundaries: "National Counties" },
        },
      ];
    },
    jsonLayers() {
      return this.wmsLayers.filter((l) => {
        return l.format === "json";
      });
    },
    // Scenarios(){
    //   return {
    //     label: "Scenarios",
    //     visible: false,
    //     nodes: [
    //       ...this.wmsLayers.filter((layer) => {
    //         return layer.parent === "Canned Responses";
    //       }),
    //     ],
    //   };
    // },
    RecursiveLayers() {
      return {
        label: "Layers",
        visible: false,
        nodes: [
          // {
          //   label: "AFCARS Infant-Toddler Removals & Placement",
          //   visible: false,
          //   nodes: [
          //     ...this.wmsLayers.filter((layer) => {
          //       return layer.parent === "AFCARS Infant-Toddler Removals & Placement";
          //     }),
          //   ],
          // },
          // {
          //   label: "NCANDS Maltreatment Reports & Recurrence",
          //   visible: false,
          //   nodes: [
          //     ...this.wmsLayers.filter((layer) => {
          //       return layer.parent === "NCANDS Maltreatment Reports & Recurrence";
          //     }),
          //   ],
          // },
          {
            label: "RTI Rarity Risk Scores",
            visible: false,
            nodes: [
              ...this.wmsLayers.filter((layer) => {
                return layer.parent === "RTI Rarity Risk Scores";
              }),
            ],
          },
          {
            label: "Community Information",
            visible: false,
            nodes: [
              ...this.wmsLayers.filter((layer) => {
                return layer.parent === "Community Information";
              }),
            ],
          },
          {
            label: "Individual & Family Services",
            visible: false,
            nodes: [
              ...this.wmsLayers.filter((layer) => {
                return layer.parent === "Individual & Family Social Services";
              }),
            ],
          },
          {
            label: "Child Welfare Policy",
            visible: false,
            nodes: [
              ...this.wmsLayers.filter((layer) => {
                return layer.parent === "Child Welfare Policy";
              }),
            ],
          },
          {
            label: "SCAN Policies",
            visible: false,
            nodes: [
              ...this.wmsLayers.filter((layer) => {
                return layer.parent === "SCAN Policies";
              }),
            ],
          },
        ],
      };
    },
    visibleLayers() {
      const layers = [
        ...this.wmsLayers.filter((layer) => {
          return layer.visible === true;
        }),
        ...this.baseLayers.filter((layer) => {
          return layer.visible === true;
        }),
      ];
      // Sort by zIndex
      return layers.sort((a, b) => {
        b.zIndex - a.zIndex;
      });
    },
  },
  methods: {
    pointToLayer(layer, latLng) {
      var icon = L.icon({
        iconUrl: `${this.baseUrl}?service=WMS&request=GetLegendGraphic&format=image%2Fpng&scale=100000&transparent=true&layer=${layer}`,
        iconSize: [this.zoom * 1.5, this.zoom * 1.5],
      });
      return L.marker(latLng, { icon: icon });
    },
    iconFunction(layer) {
      return L.icon({
        iconUrl: `${this.baseUrl}?service=WMS&request=GetLegendGraphic&format=image%2Fpng&scale=100000&transparent=true&layer=${layer}`,
        iconSize: [this.zoom * 1.5, this.zoom * 1.5],
      });
    },
    markerFunction(latLng, iconObj) {
      return L.marker(latLng, iconObj);
    },
    clearAllLayers() {
      this.wmsLayers.forEach((layer) => {
        layer.visible = false;
      });
    },
    onSelect(layer) {
      console.log(layer)
      if (layer.type === "Polygon" && layer.visible) {
        this.wmsLayers.forEach((l) => {
          if (l.type === "Polygon" && l.layers !== layer.layers) {
            l.visible = false;
          }
          if (layer.layers === "CWBNational:urb_reports_county") {
            if (l.layers === "CWBNational:parenting_services"){
              l.visible = true;
            }
          }
        });
      } else if (layer.layers === "CWBNational:urb_reports_county"){
        this.wmsLayers.forEach((l) => {
          if (l.layers === "CWBNational:parenting_services") {
            l.visible = false;
          }
        });
      }
    },
    getFeatureInfo(bounds, latLng) {
      const element = document.getElementsByClassName("vue2leaflet-map");
      let layerNames = []
      this.visibleLayers.map((layer) => {
        if (layer.label !== 'National Counties'){
          layerNames.push(layer.layers);
        }
      });
      const countyLayer = this.visibleLayers.find(l => l.label == 'National Counties')
      layerNames.unshift(countyLayer.layers)
      console.log("getFeatureInfo: ", layerNames)
      // Put county layer first
      const geoserverData = {
        service: "WMS",
        version: "1.1.1",
        request: "GetFeatureInfo",
        layers: layerNames,
        styles: "",
        srs: "EPSG:3857",
        bbox:
          bounds.sw.x +
          "," +
          bounds.sw.y +
          "," +
          bounds.ne.x +
          "," +
          bounds.ne.y,
        width: element[0].clientWidth,
        height: element[0].clientHeight,
        query_layers: layerNames,
        feature_count: 10, // MAX AMOUNT OF FEAUTRES RETURNED
        x: Math.round(latLng.x),
        y: Math.round(latLng.y),
        info_format: "application/json",
      };

      const url = new URL(this.baseUrl);
      url.search = new URLSearchParams(geoserverData);

      // Clear the popup
      this.popupContent = "";

      fetch(url)
        .then((response) => response.json())
        .then((data) => {

          if (data.features.length > 0) {
            data.features.forEach((feature) => {
              const tmp = this.visibleLayers.filter((layer) => {
                return layer.layers === `CWBNational:${feature["id"].split(".")[0]}`;
              });
              // console.log("visibleLayers: ", tmp)
              const currentLayer = tmp[0];
              if (currentLayer.layers === "CWBNational:counties") {
                this.popupContent += `<em>${feature.properties["NAMELSAD"]}, ${feature.properties["STATE"]}</em><br/>`;
              }  else {
                const displayKey = Object.keys(currentLayer.display);
                displayKey.forEach((key) => {
                  // IF LAYER IS MULTI VARIABLE!!!
                  const displayValue = currentLayer.display[key];
                  let displayString = ""
                  // Hide Rarity Data Values
                  if (currentLayer.layers === "CWBNational:lsi_le" || currentLayer.layers === "CWBNational:lsi_ca" || currentLayer.layers === "CWBNational:lsi_do" || currentLayer.layers === "CWBNational:lsi_srh" ) {
                    // this.popupContent += `<p><b>${displayValue}</b>: Contact RTI for details<p/>`;
                      // console.log(feature.properties[key])
                      // if (feature.properties[key] < 1 && feature.properties[key] >= .9) {
                      //   this.popupContent += `<p><b>${displayValue}</b>: Highest Risk, Q5<p/>`;
                      // }
                      const propertyValue = feature.properties[key];
  
                          if (typeof propertyValue === 'number') {
                            let quintile;
                            if (propertyValue >= 0.8) {
                              quintile = 'Q5, Highest Risk';
                            } else if (propertyValue >= 0.6) {
                              quintile = 'Q4';
                            } else if (propertyValue >= 0.4) {
                              quintile = 'Q3';
                            } else if (propertyValue >= 0.2) {
                              quintile = 'Q2';
                            } else {
                              quintile = 'Q1, Lowest Risk';
                           }
    
                          this.popupContent += `<p><b>${displayValue}</b>: ${quintile}</p>`;
                          }
                          else {
                            this.popupContent += `<p><b>${displayValue}</b>: Data NA</p>`;

                          }
                  } else {
                    console.log(`${feature.properties[key]} type: ${typeof feature.properties[key]}`)
                    if (typeof feature.properties[key] === "number"){
                      displayString = `<p><b>${displayValue}</b>: ${Number.parseFloat(feature.properties[key]).toFixed(3)}</p>`;
                    } else if (typeof feature.properties[key] === "string"){
                      const prop = feature.properties[key] === "" ? "Data Masked/NA" : feature.properties[key]
                      displayString = `<p><b>${displayValue}</b>: ${prop}</p>`;
                    } else if (feature.properties[key] === null) {
                      displayString = `<p><b>${displayValue}</b>: Data NA</p>`;
                    }
                    if (!this.popupContent.includes(displayString)) {
                      this.popupContent += displayString;
                    }
                  }
                });
              }
            });
            this.$refs.popupLayer["mapObject"].openPopup(this.markerLatLng);
          }
        })
        .catch((error) => console.log(error));
    },
    zoomUpdated(zoom) {
      let zoomDiff = zoom - this.zoom
      this.zoom = zoom;
      console.log("switch to counties: ", zoom)
      console.log(zoomDiff)
      if (zoomDiff > 0 || zoomDiff < 0){
        if (zoom >= 8 && zoom < 10) {
          this.switchMultipartLayer("county")
        } else if (zoom < 8) {
          this.switchMultipartLayer("state")
        } else if (zoom >= 10) {
          this.switchMultipartLayer("tracts")
        }
      }
    },
    switchMultipartLayer(dataZoomLevel){
      console.log("switchMultipartLayer: ", dataZoomLevel)
      this.wmsLayers.map(l => {
        if (l.hasOwnProperty("multipart")) {
          if (l.multipart === "state-county"){
            if (dataZoomLevel === "county"){
              l.layers = l.layers.replace("state", dataZoomLevel)
            } else if (dataZoomLevel === "state") {
              l.layers = l.layers.replace("county", dataZoomLevel)
            }
          } else if (l.multipart === "county-tract") {
            if (dataZoomLevel === "county") {
              // The naming convention is weird for urbanicity
              // instead of "county" it's "counties"
              l.layers = l.layers.replace("tracts", "counties")
            } else if (dataZoomLevel === "tracts") {
              l.layers = l.layers.replace("counties", "tracts")
            }
          }
        }
      })
    },
    boundsUpdated(bounds) {
      this.bounds = bounds;
    },
    handleClick(event) {
      this.clicks++;
      if (this.clicks === 1) {
        this.timer = setTimeout(() => {
          this.markerLatLng = Object.values(event.latlng);
          console.log('markerLatLng: ', this.markerLatLng)
          if (this.bounds !== null) {
            const boundaries = { sw: null, ne: null };
            boundaries.sw = this.$refs.HRSAmap["crs"].project(
              this.bounds.getSouthWest()
            );
            boundaries.ne = this.$refs.HRSAmap["crs"].project(
              this.bounds.getNorthEast()
            );
            this.getFeatureInfo(boundaries, event.containerPoint);
            this.clicks = 0;
          }
        }, 500);
      } else {
        clearTimeout(this.timer);
        this.clicks = 0;
      }
    },
    removeLayer(selectedLayer) {
      this.wmsLayers.forEach((layer) => {
        if (selectedLayer.label === layer.label){
          layer.visible = false;
        }
      });
    },
    hideLegend(legendId){
      var link = document.getElementById(legendId);
      if (link.style.display === 'none'){
        link.style.display = ''
      } else {
        link.style.display = 'none';
      }
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

.container-map {
  height: calc(100% - 50px);
  margin: 0;
}

.container-map--banner p {
  margin-bottom: 0;
}

.vue2leaflet-map {
  z-index: 1;
  cursor: pointer;
}

.leaflet-grab {
  cursor: pointer;
}

/* Change cursor when mousing over clickable layer */
.leaflet-clickable {
  cursor: pointer !important;
}
/* Change cursor when over entire map */
.leaflet-container {
  cursor: pointer !important;
}
/* .top-layer-panel{
  max-height: 20%;
  position: sticky;
} */
.container-dropdown {
  display: inline-block;
  width: 98%;
  z-index: 99;
  position: absolute;
  margin: 0rem 2rem 0.5rem 0rem;
  padding-top: 20px;
}
.close-sidebar{
  padding-right: 5px;
}
.container-dropdown--recursive {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding: 1rem 1rem;
  margin: 0rem 1rem 0rem 0rem;
  border-radius: 5px;
}

.container-dropdown--recursive p {
  margin-top: 12px;
  top-padding: 45px;
}

.container-dropdown--questions {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: rgb(64, 102, 140);
  color: white;
  width: 100%;
  padding: 1rem 1rem;
  margin-left: 1rem;
  border-radius: 5px;
  top-padding: 45px;
}
.dropdown-layers {
  position: relative;
  display: inline-block;
  width: 100%;
  padding: 0rem 0.5rem 0.4rem 0.5rem;
  margin-bottom: 8px;
}

.dropdown-layers > div {
  display: block;
  position: absolute;
  background: white;
  width: 80%;
  padding: 0.5rem;
  margin: 1.2rem 0rem 1rem -0.6rem;
  border: 1px solid grey;
  border-radius: 5px;
  z-index: 2;
}
.fa-2x{
font-size: 30px;
font-weight: light;
margin-right: -2px;
}
.dropdown-layers > button {
  float: right;
  margin-top: 19px;
  padding: 9px 0px;
  width: 30%;
}

#btn-clearall {
  padding: 9px 8px;
  background-color: rgb(158, 42, 43);
  border-color: rgb(158, 42, 43);
}

#btn-clearall:hover {
  background-color: rgba(158, 42, 43, 0.85);
}

#btn-clearall--mobile {
  background-color: rgb(158, 42, 43);
  border-color: rgb(158, 42, 43);
  display: none;
}

#btn-clearall--mobile:hover {
  background-color: rgba(158, 42, 43, 0.85);
}

.leaflet-popup-content {
  text-align: center;
}
.legend-toolbar{
  /* border: solid 1.5px darkgray; */
  max-width: 250px;
  display: flex;
}

.legend-btn {
  font-size: 1.1rem;
  background: white;
  padding: 2px 8px;
  border: none;
  border-radius: 2px;
}

.legend-btn:hover {
  background: rgb(225, 225, 225);
}

.legend-content {

  max-height: 450px;
  overflow-y: initial;
  max-width: 250px;
  box-shadow: 5px 5px 2px rgba(0, 0, 0, 0.4);
  z-index: 1;
}

.legend-content p {
  margin: 10px 0px 0px 0px;

}

.layer-content {
  opacity: 0.87;
  position: flex;
  background: white;
  /* padding: 0.5rem; */
  transform: translate(-10px, -52px);
  width: 400px;
  scroll-behavior: smooth;
  /* height: 80%; */
  overflow-x: hidden;
  overflow-y: visible;
  z-index: 1;
  box-shadow: 5px 5px 8px rgba(0, 0, 0, 0.5);
}
.close-sidebar{
 position: right;
}
.layer-pill {
  width: max-content;
  word-break: break-word;
  overflow-wrap: break-word;
  max-width: 300px;
  border-radius: 15px;
}

.layer-bubbles{
  margin: 2%;
}

.bubble-container{
  width: 300px;
}
.layer-content p {
  margin: 10px 0px 0px 0px;
}

.show-layer-btn {
  border: none;
  background: transparent;
  padding-bottom: 30px;
}

.close-sidebar {
  height: 10px;
  width: 392px;
}

.popup-info{
  overflow-y: scroll;
  max-height: 200px;
}

.on-hover-style:hover {
  opacity: 0.33;
  /* font-size: 1.2em; */
  /* transition: 1s ease-out; */
}

.fa.fa-solid.fa-play.fa-4x:hover {
  font-size: 6em;
  transition: 1s ease-out;
}

.fa.fa-solid.fa-backward.fa-1x:hover {
  font-size: 2em;
  transition: 1s ease-out;
}



/* Laptop styles */
@media only screen and (max-width: 1024px) {
  .container-dropdown--recursive {
    margin: 0rem;
  }

  .container-dropdown--questions {
    margin-left: 0.5rem;
  }
}
/* small laptop styles */
@media only screen and (max-width: 920px) {
  .container-dropdown {
    flex-direction: column-reverse;
  }
  .container-dropdown--recursive {
    margin-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .leaflet-right .leaflet-control-geosearch form {
    width: 270px;
  }
}

/* mobile styles */
@media only screen and (max-width: 550px) {
  #btn-clearall {
    display: none;
  }

  .dropdown-layers {
    height: 92px;
  }

  .dropdown-layers > div {
    width: 100%;
  }

  #btn-clearall--mobile {
    width: 99%;
    display: block;
  }

  .leaflet-right .leaflet-control-geosearch form {
    width: 150px;
  }
}
</style>
