import Vue from "vue";
import Router from "vue-router";
import Dashboard from "@/components/Dashboard";
import About from "@/components/About";
import Querity from "@/components/Querity"

Vue.use(Router);

export default new Router({
  mode: 'history',
  routes: [
    {
      path: "/",
      component: Dashboard,
    },
    {
      path: "/about",
      component: About,
    },
    {
      path: "/data",
      component: Querity,
    },
  ],
});
