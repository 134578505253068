<template class="menu-section">
  <div class="tree-menu" @mouseover="onMouseOver" @mouseleave="onMouseLeave">
    <div class="label-wrapper" @click="toggleChildren">
      <div :style="hasChildren ? indentNode : indentLabel" class="tree-label">
        <i v-if="hasChildren" class="chevron fa" :class="iconClasses"></i>
        <b-form-checkbox v-show="!hasChildren" @input="onSelect(layers)" v-model="layers.visible" :value="true"
          :unchecked-value="false" class="selectable-words">{{ layers.label }}</b-form-checkbox>
        <p class="word-wrap selectable-words" :style="showChildren ? afterDropdownText : null" v-if="hasChildren">
          {{ layers.label }}
        </p>
        <button v-show="!hasChildren && layers.parent === 'Canned Responses'" @click="infoModalFunc(layers.label)"
          style="background: transparent; border:none;" class="on-hover-style">
          <i class="fa fa-info-circle info-size"></i>
        </button>
      </div>
    </div>
    <div v-show="showChildren" v-if="hasChildren" :style="depth === 0 ? rootStyle : null">
      <RecursiveDropdown v-for="(node, i) in layers.nodes" :key="i" :layers="node" :depth="depth + 1"
        :on-select="onSelect" />
    </div>
    <b-modal v-model="infoModalShow"  hide-footer :title="infoHeader" size="lg" scrollable>
      <div>
        <InfoModal :info="infoLayer"></InfoModal>
      </div>
    </b-modal>
  </div>
</template>

<script>
import infoData from "../assets/data/about_layers_info.json";
import InfoModal from './InfoModal.vue'
import { BIcon } from 'bootstrap-vue'
export default {
  name: "RecursiveDropdown",
  props: {
    layers: Object,
    depth: Number,
    onSelect: Function,
  },
  data() {
    return {
      
      showChildren: this.depth === 0,
      
      
      timer: null,
      afterDropdownText: {
        marginLeft: "0"
      },
      rootStyle: {
        maxHeight: "fit-content",
        overflowY: "visible",
        overflowX: "hidden",
        paddingBottom: "5%",
      },
      infoHeader: "",
      infoLayer: "",
      infoModalShow: false,
      layerInfo: infoData.layerInfo,
    }
  },
  components: {
    InfoModal,
    BIcon
  },
  computed: {
    hasChildren() {
      return this.layers.nodes && this.layers.nodes.length;
    },
    iconClasses() {
      return {
        'fa-caret-right': !this.showChildren,
        'fa-caret-down': this.showChildren
      }
    },
    indentNode() {
      return { marginLeft:`calc(${this.depth * 25}px)` }
    },
    indentLabel() {
      return { marginLeft: `calc(${this.depth * 25 + 12}px)` }
    }
  },
  methods: {
    onMouseOver() {
      clearTimeout(this.timer);
    },
    onMouseLeave() {
      if (this.depth === 0) {
        this.timer = setTimeout(() => { this.showChildren = false; }, 30000);
      }
    },
    toggleChildren() {
       if(this.hasChildren) this.showChildren = !this.showChildren;
    },
    infoModalFunc(layerLabel){
      let selectedLayer = this.layerInfo.find(l => l.label === layerLabel)
      this.infoHeader = selectedLayer.title
      this.infoLayer = selectedLayer.label
      this.infoModalShow = !this.infoModalShow
    }
    /*
    onSelect() {
      this.layers.visible = !this.layers.visible;
    }
    */
  }
}
</script>
<style scoped>

.label-wrapper {
  width: 100%;
  display: block;
  position: relative;
  font-size: 16px;
  margin-left: 0.5rem;
  padding-right: 1.5rem;
  cursor: pointer;
}

.tree-label {
  display: flex;
}

.tree-label p {
  margin: 0;
  margin-left: 0.2rem;
}

.tree-label {
  margin: 3px 5px 0px 0px;
}

.tree-label i {
  margin: 3px 10px 0px 0px;
  cursor: pointer;
}

.word-wrap {
  overflow-wrap: break-word;
}

.selectable-words:hover{
  box-shadow: inset 100% 0 0 0 #54b3d6;
  transition: all .3s ease-in-out;
  color: #54b3d6;
  cursor: pointer;
}
.on-hover-style:hover {
  opacity: 0.33;
  transition: 1s ease-out;
}
.info-size {
  font-size: 150%;
  margin: 2% !important;
}
</style>
