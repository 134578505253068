<template>
  <div class="dropdown " @mouseleave="show = false" v-bind:class="{ active: show }">
    <button class="dropdown-dropbtn" @click="show = !show" :style="show ? showDropdownStyle : null">
      <i v-if="!show" class="fa fa-caret-right"></i>
      <i v-else class="fa fa-caret-down"></i>
      <p :style="show ? afterDropdownText : null">{{ text }}</p>
    </button>
    <div class="dropdown-content" v-show="show">
      <div class="dropdown-item" v-for="item in options" :key="item.id">
        <!-- name attribute with same value is required for grouping radio buttons -->
        <input type="radio" name="research-questions" :checked="item.id==model" class="shadow" :value="item.id" @input="handleSelect(item.id)">
        <span class="ml-2" style="color: black; white-space: normal">{{ item.text }}</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      show: false,
      showDropdownStyle: {
        background: "white",
        borderBottom: "1px solid rgba(0,0,0,0)",
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
      },
      afterDropdownText: {
        marginLeft: "0.8rem"
      }
    };
  },
  props: {
    text: {
      type: String,
    },
    options: {
      type: Array,
    },
    model: {
      type: Number
    },
  },
  methods: {
    handleSelect(questionId) {
      this.$emit("question-select", questionId);
    },
  },
};
</script>
<style scoped>
/* Dropdown Button */
.dropdown-dropbtn {
  font-size: 16px;
  border: none;
  width: 100%;
  display: flex;
  align-items: center;
  background: white;
  border-radius: 5px;
  border: 1px solid grey;
  padding-left: 12px;
}

.dropdown-dropbtn p {
  text-align: left;
  margin: 0.5rem 0rem 0.5rem 1rem;
}

.dropdown-content--btn {
  font-size: 16px;
  border: none;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;
}

.dropdown-content--btn p {
  margin: 0.5rem 0rem;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
  width: 100%;
  margin: 1rem 0.4rem;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: block;
  position: absolute;
  background-color: white;
  padding: 0rem 0rem;
  width: 100%;
  border: 1px solid grey;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top: none;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 2;
}

.dropdown-content .dropdown- {
  width: 100%;
  color: black;
  text-decoration: none;
  display: block;
  margin: 0.5rem 0.5rem;
  padding: 0.5rem 0rem;
}

/* Links inside the dropdown */
.dropdown-content .dropdown-item {
  width: 100%;
  color: black;
  text-decoration: none;
  display: block;
  margin: 0.5rem 0.5rem;
  padding: 0.5rem 0rem;
  background: none;
}

</style>
