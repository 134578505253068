<template>
    <div class="info-modal-content">
        <p>{{ modalBody }}</p>
        <img v-show="modalImage ===1" src="../assets/images/MaltreatmentLSI.jpg" />
        <img v-show="modalImage === 2" src="../assets/images/PolicyMaltreatment.jpg" />
        <img v-show="modalImage === 3" src="../assets/images/UrbanicityMaltreatment.png" />
    </div>
</template>

<script>
import infoData from "../assets/data/about_layers_info.json";
export default {
    data() {
        return {
            layerInfo: infoData.layerInfo,
            modalHeader: "",
            modalBody: "",
            modalImage: null
        }
    },
    props: {
        info: String,
    },
    watch: {
        info: {
            handler: function () {
                this.updateModal(this.info);
            },
            immediate: true,
        },
    },
    methods: {
        updateModal(info){
            var newInfo = this.layerInfo.find(i => i.label === info)
            this.modalImage = newInfo.scenario
            this.modalHeader = newInfo.title
            this.modalBody = newInfo.info
        }
    }
}
</script>
<style scoped>
img {
    width: 600px;
    display: block;
    margin: auto;
}
</style>