<!-- Splash.vue -->
<template>
    <div class="splash-overlay" @click="beginExploring">
    <div class="splash-modal">
      <h1>RTI Rarity™</h1>
      <h4>Where are the biggest local social inequities in health outcomes across the US?</h4>
      <p>
        The RTI Rarity dashboard answers this question using data science and machine learning to analyze a vast data library of more than 200 measures in multiple domains of social, and behavioral determinants of health.
        Use this tool to explore your own community’s risk for inequities in life expectancy, cancer mortality, drug overdoses, and more.
        See where the resources are relative to the areas with the greatest need.
    </p>
      <button @click.stop="beginExploring">Begin Exploring</button>
    </div>
  </div>
  </template>
  
  <script>
  export default {
    methods: {
      beginExploring() {
        // Emit an event to inform the parent component that the user wants to begin exploring.
        this.$emit("start-exploring");
      },
    },
  };
  </script>
  
  <style scoped>
/* CSS styles for the splash overlay */
.splash-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent dark overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999; /* Ensure it's above other content */
}

/* CSS styles for the splash modal */
.splash-modal {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
  max-width: 80%;
}
</style>
