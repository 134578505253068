<template>
    <div class="querity-container">
      <h1>Querity goes here</h1>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.querity-container {
    display: flex;
    flex-direction: column;
}
</style>