<template>
  <div>
    <RecursiveDropdown :layers="layers" :depth="0" :on-select="onSelect"></RecursiveDropdown>
  </div>
</template>

<script>
import RecursiveDropdown from "./RecursiveDropdown.vue";

export default {
  props: {
    layers: Object,
    
  },
  methods: {
    onSelect(layer) {
      this.$emit("on-select", layer);
    },
  },
  components: {
    RecursiveDropdown,
  
  },
};
</script>
